<template>
  <div ref="container"></div>
</template>

<script>
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';

export default {
  name: 'app',
  mounted() {
    this.init();
    this.animate();
    this.timer = null;
    document.addEventListener('visibilitychange', this.onVisibilityChange);

  },
  data() {
    return {
      isPageVisible: true,
    }
  },
  methods: {
    beforeDestroy() {
      // Remove event listener for visibility change
      document.removeEventListener('visibilitychange', this.onVisibilityChange);
    },
    init() {
      this.scene = new THREE.Scene();

      this.camera = new THREE.PerspectiveCamera(
        75,
        window.innerWidth / window.innerHeight,
        0.1,
        1000
      );
      this.camera.position.z = 5;

      this.renderer = new THREE.WebGLRenderer({ antialias: true });
      this.renderer.setSize(window.innerWidth, window.innerHeight);
      this.renderer.setPixelRatio(window.devicePixelRatio * 1.5);
      this.renderer.outputEncoding = THREE.sRGBEncoding;
      this.$refs.container.appendChild(this.renderer.domElement);

      this.controls = new OrbitControls(this.camera, this.renderer.domElement);
      this.controls.enableDamping = true;
      this.controls.dampingFactor = 0.05;

      const geometry = new THREE.SphereGeometry(2, 32, 32);
      const material = new THREE.PointsMaterial({
        size: 0.05,
        vertexColors: true,
      });

      const positions = geometry.attributes.position.array;
      const colors = [];

      for (let i = 0; i < positions.length; i += 3) {
        const x = positions[i];
        const y = positions[i + 1];
        const z = positions[i + 2];

        const distance = Math.sqrt(x * x + y * y + z * z);
        const hue = (Math.atan2(y, x) + Math.PI) / (Math.PI * 2);

        const saturation = distance / 2;
        const value = (1 - Math.abs(z) / 2) * 0.8 + 0.2;

        const color = new THREE.Color().setHSL(hue, saturation, value);

        const luminance = color.r * 0.299 + color.g * 0.587 + color.b * 0.114;
        if (luminance < 0.2 || luminance > 0.8) {
          // If the colors that are too dark or too bright are not visible, change the value of the threshold.
          color.setHSL(hue, saturation, 0.5);
        } else {
          // Lighten or darken the color based on its luminance value
          const colorValue = (luminance < 0.5) ? 0.1 : -0.1;
          color.offsetHSL(0, 0, colorValue);
          // If the color has more than 80% white, reduce it
          if (luminance + colorValue > 0.8) {
            color.offsetHSL(0, 0, -0.1);
          }
        }

        colors.push(color.r, color.g, color.b);
      }

      geometry.setAttribute('color', new THREE.Float32BufferAttribute(colors, 3));
      const points = new THREE.Points(geometry, material);
      this.scene.add(points);

      window.addEventListener('resize', this.onWindowResize);

      this.$refs.container.addEventListener('mousedown', this.onMouseDown);
      this.$refs.container.addEventListener('touchstart', this.onMouseDown);
      this.$refs.container.addEventListener('mouseup', this.onMouseUp);
      this.$refs.container.addEventListener('touchend', this.onMouseUp);


      // Create the timer for auto rotate if user not interact with the scene at load or after 10 seconds.
      this.controls.enabled = true;
      this.controls.autoRotate = true;
      this.controls.autoRotateSpeed = 0.5;

      console.log(1, this.camera)
    },
    animate() {
      if (this.isPageVisible) {
        requestAnimationFrame(this.animate);
        this.controls.update();
        this.renderer.render(this.scene, this.camera);
      } else {
        setTimeout(() => {
          requestAnimationFrame(this.animate);
        }, 1000);
      }
    },
    onWindowResize() {
      // this.camera.aspect = window.innerWidth / window.innerHeight;
      // this.camera.updateProjectionMatrix();
      // this.renderer.setSize(window.innerWidth, window.innerHeight);

    },
    onVisibilityChange() {
      this.isPageVisible = !document.hidden;
      console.log('Page visibility changed to: ', this.isPageVisible)
    },
    onMouseDown() {
      // this.controls.enabled = true;
      // clearTimeout(this.timer);

      clearTimeout(this.timer);
      this.controls.enabled = true;
      this.controls.autoRotate = false;
      this.controls.autoRotateSpeed = 0;

    },
    onMouseUp() {
      // this.controls.enabled = false;

      // this.timer = setTimeout(() => {
      //   this.controls.enabled = true;
      //   this.controls.autoRotate = true;
      //   this.controls.autoRotateSpeed = 0.5;
      // }, 10000);

      // After 5 seconds reset zoom and then start timer for auto rotate if NO user interaction has happened during that 5 seconds.        

            this.camera = new THREE.PerspectiveCamera(
        75,
        window.innerWidth / window.innerHeight,
        0.1,
        1000
      );

      this.controls = new OrbitControls(this.camera, this.renderer.domElement);
      this.controls.enableDamping = true;
      this.controls.dampingFactor = 0.05;

      this.camera.position.z = 5;
      this.camera.position.x = 0;


      this.controls.enabled = true;
      this.controls.autoRotate = true;
      this.controls.autoRotateSpeed = 0.5;
      console.log("suo", this.camera)



      // this.timer = setTimeout(() => {
      //   // Set the camera to the initial position
      //   this.camera = new THREE.PerspectiveCamera(
      //   75,
      //   window.innerWidth / window.innerHeight,
      //   0.1,
      //   1000
      // );

      // console.log("suo", this.camera.position)
      // this.camera.position.z = 5;
      // // this.camera.position.x = 0;
      // // this.camera.position.y = 3.061616997868383e-16;
      // // console.log("su2o", this.camera.position)

      // }, 5000);
    },
  },
};
</script>

<style>
  body {
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
</style>
